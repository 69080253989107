<template>
<CContainer class="background-bsre-page d-flex flex-column content-center min-vw-100 min-vh-100 mx-0">
    <div class="brand-img-container text-center d-block" style="margin-top:5%">
        <img class="brand-img" :src="logo" alt="brand"/>
    </div>
    <CRow  v-if="showData" style="width: 100% ">
        <CCol>
            <CCardGroup>
                <CCard class="p-0 border-0">
                    <CCardBody class="p-2">
                        <div class="row mt-1">
                            <div class="col-12 pl-4 pr-4 text-center">
                                <h4 class="mb-1">Verifikasi Tanda Tangan Elektronik</h4>
                                <p class="small mb-1"><em>Digital Signature Verification</em></p>
                            </div>
                        </div>
                        <div class="row mt-1">
                            <div class="col-12 pl-4 pr-4 text-center">
                                <h5 class="mb-1">Kementerian Pertahanan Indonesia menyatakan bahwa:</h5>
                                <p class="small mb-1"><em>The Indonesian Ministry of Defense states that:</em></p>
                            </div>
                        </div>
                    </CCardBody>
                </CCard>
            </CCardGroup>
        </CCol>
    </CRow>
    <CRow  v-if="showData" style="width: 100%">
        <CCol>
            <CCardGroup>
                <CCard class="p-0 shadow-lg border-0 rounded">
                    <CCardBody class="p-2">
                        <div class="row mt-1">
                            <div class="col-12 pl-4 pr-4 text-center" v-if="isValid">
                                <p class="mb-0 text-success"><strong>SAH</strong></p>
                                <p class="small mb-1 text-success"><em>Valid</em></p>
                            </div>
                            <div class="col-12 pl-4 pr-4 text-center" v-else>
                                <p class="mb-0 text-danger"><strong>TIDAK SAH</strong></p>
                                <p class="small mb-1 text-danger"><em>Not Valid</em></p>
                            </div>
                        </div>
                        <div class="row mt-1">
                            <div class="col-12 pl-4 pr-4 text-left">
                                <p class="mb-0">Nama Dokumen : </p>
                                <p class="small mb-1"><em>Document Title : </em></p>
                                <p class="mb-2"><strong>{{bsreData.detil_doc.perihal}} </strong></p>
                            </div>
                        </div>
                        <div class="row mt-0 text-left">
                            <div class="col-12 pl-4 pr-4">
                                <p class="mb-0">Nomor Dokumen : </p>
                                <p class="small mb-1"><em>Document Number : </em></p>
                                <p class="mb-2"><strong>{{bsreData.detil_doc.doc_no}} </strong></p>
                            </div>
                        </div>
                        <div class="row mt-0 text-left">
                            <div class="col-12 pl-4 pr-4">
                                <p class="mb-0">Tanggal Pembuatan Dokumen : </p>
                                <p class="small mb-1"><em>Document Created Date : </em></p>
                                <p class="mb-2"><strong>{{bsreData.detil_doc.created_at}} </strong></p>
                            </div>
                        </div>
                        
                        <div class="row mt-0 text-left">
                            <div class="col-12 pl-4 pr-4">
                                <p class="mb-0">Penerbit : </p>
                                <p class="small mb-1"><em>Publisher : </em></p>
                                <p class="mb-2"><strong>{{bsreData.penerbit}} </strong></p>
                            </div>
                        </div>

                        <div class="row mt-0 text-left">
                            <div class="col-12 pl-4 pr-4">
                                <p class="mb-0">Telah ditandatangani oleh : </p>
                                <p class="small mb-1"><em>Has been signed by : </em></p>
                            </div>
                            <div class="col-12 pl-4 pr-4">
                                <div class="card bg-light border-0 rounded">
                                    <div class="card-body">
                                        <div class="row" v-for="(data, index) in bsreData.user_esign_bsre" v-bind:key="index">
                                            <div class="col-2">
                                                <font-awesome-icon icon="user" class="mr-10"/>
                                            </div>
                                            <div class="col-10 text-left">
                                                <p class="mb-2"><strong>{{data.user.signer_name}}</strong></p>
                                                <p class="mb-0">Jabatan pengguna : </p>
                                                <p class="small mb-1"><em>User position : </em></p>
                                                <p class="mb-2"><strong>{{data.user.position}}</strong></p>

                                                <p class="mb-0">Waktu Persetujuan Dokumen : </p>
                                                <p class="small mb-1"><em>Document Signed Date : </em></p>
                                                <p class="mb-2"><strong>{{data.signed_in}}</strong></p>
                                                <p class="mb-0">
                                                    <font-awesome-icon v-if="bsreData.status_dokumen.dokumen_utuh" icon="check-circle" class="mr-10 text-success"/>  
                                                    <font-awesome-icon v-else icon="times-circle" class="mr-10 text-danger"/>  
                                                        Dokumen Utuh</p>
                                                <p class="mb-0">
                                                    <font-awesome-icon v-if="data.status_sertifikasi.sertifikasi_terpecaya" icon="check-circle" class="mr-10 text-success"/>  
                                                    <font-awesome-icon v-else icon="times-circle" class="mr-10 text-danger"/>  
                                                        Sertifikat Terpercaya</p>
                                                <p class="mb-0">
                                                    <font-awesome-icon v-if="data.status_sertifikasi.sertifikasi_berlaku" icon="check-circle" class="mr-10 text-success"/>  
                                                    <font-awesome-icon v-else icon="times-circle" class="mr-10 text-danger"/>  
                                                        Sertifikat Berlaku</p> 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12 text-center">
                                <p class="mb-1"><strong><font-awesome-icon icon="check-circle" class="mr-10 text-success"/> Adalah benar dan tercatat dalam sistem kami.</strong></p>
                                <p class="small mb-1"><em>That is true and it is recorded in our system.</em></p>
                            </div>
                        </div>
                        
                        <div class="row mb-1 p-1">
                            <div class="col-12 text-center">
                                <p class="mb-0">Untuk memastikan kebenaran pernyataan ini pastikan URL pada browser anda adalah https://dtakah.kemhan.go.id/</p> 
                                <p class="small mb-1"><em>If you wish to check the validity of this statement, please ensure the URL of your browser is https://dtakah.kemhan.go.id/</em></p>
                            </div>
                        </div>
                    </CCardBody>
                </CCard>
            </CCardGroup>
        </CCol>
    </CRow>
</CContainer>
</template>

<script>
const logo = require('@/assets/img/logo.png')

export default {
    name: "BSRE Page",
    data() {
        return {
            logo,
            showData: false,
            bsreData: null,
            isValid: false
        }
    }, 
    watch: {},
    computed: {
        urlData() {
			return this.$route.query
		},
    },
    mounted(){
        this.getBSREData()
    },
    methods: {
        getBSREData() {
            if(this.urlData.hash_bsre_qr != null ) {
                this.$store.dispatch(
                    {
                        type: 'bsre/getBSREDetailData',
                        hash_bsre_qr: this.urlData.hash_bsre_qr
                    }
                ).then((res) => {
                    if (res) {
                        this.bsreData = res.result
                        this.showData = true
                        if(this.bsreData.status_validasi_dokumen.toLowerCase() == "valid") {
                            this.isValid = true 
                        } else {
                            this.isValid = false
                        }
                    } else {
                        this.showData = false
                    }
                })
            }
            
        },
    }
}
</script>

<style scoped>
.container{
    padding: 20px;
}
.brand-img{
    width: 150px;
    height: 150px;
    margin-bottom: 20px;
}
.login-form-container{
    width: 400px!important;
}
.background-bsre-page {
    background: white
}

</style>